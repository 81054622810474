<template>
  <v-card
    flat
  >
    <v-form
      ref="form"
    >
      <v-row>
        <v-col
          cols="2"
        >
          <v-select
            v-model="form.province"
            :label="$vuetify.lang.t('$vuetify.region.province')"
            :items="provinceItems"
            :rules="rules.province"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleProvinceChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.city"
            :label="$vuetify.lang.t('$vuetify.region.city')"
            :items="cityItems"
            :rules="rules.city"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleCityChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.county"
            :label="$vuetify.lang.t('$vuetify.region.county')"
            :items="countyItems"
            :rules="rules.county"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleCountyChange"
          ></v-select>
        </v-col>

        <v-col
          cols="2"
        >
          <v-select
            v-model="form.town"
            :label="$vuetify.lang.t('$vuetify.region.town')"
            :items="townItems"
            :rules="rules.town"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleTownChange"
          ></v-select>
        </v-col>

        <v-col
          cols="4"
        >
          <v-select
            v-model="form.village"
            :label="$vuetify.lang.t('$vuetify.region.village')"
            :items="villageItems"
            :rules="rules.village"
            menu-props="auto"
            item-text="name"
            item-value="id"
            clearable
            @change="handleVillageChange"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="6"
        >
          <v-file-input
            v-model="form.uploadFile"
            :label="$vuetify.lang.t('$vuetify.common.chooseFiles')"
            :rules="rules.uploadFile"
            chips
          ></v-file-input>

        </v-col>
        <v-col
          cols="6"
        >
          <div
            class="mt-8"
          >
            {{$vuetify.lang.t('$vuetify.patient.downloadTip')}}
            <v-btn
              color="blue"
              text
              @click="handleDownloadTemplate"
            >
               {{$vuetify.lang.t('$vuetify.common.clickDownload')}}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-form>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="info darken-1"
        @click="handleCancel"
      >
         {{$vuetify.lang.t('$vuetify.common.cancel')}}
      </v-btn>
      <v-btn
        color="primary darken-1"
        @click="handleBatchSubmit"
      >
         {{$vuetify.lang.t('$vuetify.common.submit')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import { saveAs } from 'file-saver';
  import { excelFileName } from '@/utils/validators';

  export default {
    props: {
      isSign: {
        type: Boolean,
        default: () => false
      }
    },
    data() {
      return {
        provinceItems: [],
        cityItems: [],
        countyItems: [],
        townItems: [],
        villageItems: [],

        form: {
          uploadFile: null,
          province: null,
          city: null,
          county: null,
          town: null,
          village: null,
          credentialType: 'I'
        },

        rules: {
          uploadFile: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.required', [this.$vuetify.lang.t('$vuetify.common.chooseFiles')]),
            (v) =>
              ( v && excelFileName.test(v.name)) || this.$vuetify.lang.t('$vuetify.rule.mustIsExcel')
          ],
          province: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.province')])
          ],
          city: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.city')])
          ],
          county: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.county')])
          ],
          town: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.town')])
          ],
          village: [
            (v) =>
              !!v || this.$vuetify.lang.t('$vuetify.rule.selected', [this.$vuetify.lang.t('$vuetify.region.village')])
          ]
        }
      }
    },

    computed: {
      ...mapGetters({
        regions: 'region/regions'
      })
    },

    created () {
      this.initialize()
    },

    methods: {

      ...mapActions({
        regionQueryAll: 'region/queryAll',
        downloadPatientImportTemplate: 'patient/downloadPatientImportTemplate',
        downloadPatientGrantImportTemplate: 'patient/downloadPatientGrantImportTemplate'
      }),

      initialize () {
        this.resetFrom()
        this.getRegion({parentId: ''})
      },

      getRegion (params = {}, target = 'provinceItems') {
        return new Promise((resolve, reject) => {
          this.regionQueryAll(params).then(() => {
            this[target] = this.regions
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      },

      handleProvinceChange (id) {
        this.cityItems = []
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.city = null
        this.form.county = null
        this.form.town = null
        this.form.village = null
        if (id) {
          this.getRegion({  parentId: id }, 'cityItems')
        }
      },

      handleCityChange (id) {
        this.countyItems = []
        this.townItems = []
        this.villageItems = []
        this.form.county = null
        this.form.town = null
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'countyItems')
        }
      },

      handleCountyChange (id) {
        this.townItems = []
        this.villageItems = []
        this.form.town = null
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'townItems')
        }
      },

      handleTownChange (id) {
        this.villageItems = []
        this.form.village = null

        if (id) {
          this.getRegion({  parentId: id }, 'villageItems')
        }
      },

      handleVillageChange () {

      },

      resetFrom () {
        for (let key in this.form) {
          this.form[key] = null
        }
        this.form.credentialType = 'I'
        this.$nextTick ( () => {
          this.$refs.form.resetValidation()
        })

      },
      handleCancel() {
        this.$emit('cancel', true)
      },
      handleSubmit() {
        if (this.$refs.form.validate()) {
          const data = Object.assign({}, this.form)
          if (this.editStatus) {
            this.$emit('update', data);
          } else {
            this.$emit('submit', data);
          }
        }
      },

      handleDownloadTemplate () {
        if (this.isSign) {
          this.downloadPatientGrantImportTemplate().then( (response) => {
            saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${ this.$vuetify.lang.t('$vuetify.patient.batchCreateSign') }.xlsx`)
          })
        } else {
          this.downloadPatientImportTemplate().then( (response) => {
            saveAs(new Blob([response.data], { type: 'application/octet-stream' }), `${ this.$vuetify.lang.t('$vuetify.patient.label') }.xlsx`)
          })
        }

      },

      handleBatchSubmit () {
        if (this.$refs.form.validate()) {
          this.$emit('submit', this.form);
        }
      }
    }
  }
</script>
